import React from 'react';
import {Card, Text, TextPriority} from 'wix-ui-tpa/cssVars';
import {useLocaleKeys} from '../../../locale-keys/LocaleKeys';
import {SPECS} from '../constants';
import {useExperiments} from '@wix/yoshi-flow-editor';

export enum ShippingInfoBoxDataHook {
  root = 'ShippingInfoBox.root',
  instructionsTitle = 'ShippingInfoBox.instructionsTitle',
}

export interface ShippingInfoBoxProps {
  instructions?: string;
}

const ShippingInfoBoxInternal = ({instructions}: ShippingInfoBoxProps) => {
  const localeKeys = useLocaleKeys();
  const {experiments} = useExperiments();

  if (!experiments.enabled(SPECS.AddShippingInstructionsInCheckout)) {
    return null;
  }

  return instructions ? (
    <Card data-hook={ShippingInfoBoxDataHook.root}>
      <Card.Container>
        <Text tagName={'h4'} data-hook={ShippingInfoBoxDataHook.instructionsTitle} priority={TextPriority.primary}>
          {localeKeys.checkout.deliveryMethod.deliveryInstructions.title()}
        </Text>
      </Card.Container>
    </Card>
  ) : null;
};

export const ShippingInfoBox = React.memo(ShippingInfoBoxInternal);
